'use strict';

if (module.hot) {
	module.hot.accept();
}

const chapter = require('../data/chapter.json').chapter;

import '../styles/index.scss';

import Flickity from 'Flickity';
import { throttle, debounce } from 'throttle-debounce';

window.onload = function() {
	const next_chapter_url = (chapter.next.url) ? 'http://linkinbio.michelesselbruegge.com'+chapter.next.url : null;
	const current_chapter_id = chapter.id;
	if (chapter.virus_level > 0) {
		let bug_wrapper = document.querySelector('.bug-wrapper');
		let current_bug = null;
		let getRandomInt = (min, max) => {
    	min = Math.ceil(min);
    	max = Math.floor(max);
    	return Math.floor(Math.random() * (max - min + 1)) + min;
		};
		let updateBug = () => {
			if (Math.random() <= 0.33333) {
				bug_wrapper.style.backgroundImage = 'url(http://linkinbio.michelesselbruegge.com/public/bugs/'+chapter.virus_level+'/v_01.gif)';
			} else if (Math.random() > 0.33333 && Math.random() <= 0.66666) {
				bug_wrapper.style.backgroundImage = 'url(http://linkinbio.michelesselbruegge.com/public/bugs/'+chapter.virus_level+'/v_02.gif)';
			} else {
				bug_wrapper.style.backgroundImage = 'url(http://linkinbio.michelesselbruegge.com/public/bugs/'+chapter.virus_level+'/v_03.gif)';
			}
		};
		let updateBugInterval = setInterval(() => {
			updateBug();
		}, getRandomInt(10,100)*1000);
		setTimeout(() => {
			bug_wrapper.style.opacity = 1;
		}, 500);
		updateBug();

	}

	let scrollTo = require('scroll-to');

	// "real" viewport-height for mobile browsers – do once, before flickity loads
	let vph = window.innerHeight;
	let browser_bars_height = window.outerHeight - vph;
	let vh = vph * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);

	let main_container = document.querySelector('.container.main');

	let info_container = {
		el_a: document.querySelector('.container.aside.left'),
		el_b: document.querySelector('.container.aside.right'),
		el_a_height: null,
		el_b_height: null,
		el_a_top: null
	};

	let no_chapter_transition_el = document.querySelectorAll('.no-chapter-transition');
	if (!chapter.next.title && !chapter.next.url) {
		// if end
		for (var i = no_chapter_transition_el.length - 1; i >= 0; i--) {
			no_chapter_transition_el[i].addEventListener('click', (ev) => {
				ev.preventDefault();
			});
		}
	} else {
		for (var i = no_chapter_transition_el.length - 1; i >= 0; i--) {
			no_chapter_transition_el[i].addEventListener('click', (ev) => {
				ev.preventDefault();
				alert('🔮 The Chapter is not yet available.');
			});
		}
	}

	let chapter_transition_el = document.querySelectorAll('.chapter-transition');
	for (var i = chapter_transition_el.length - 1; i >= 0; i--) {
		chapter_transition_el[i].addEventListener('click', (ev) => {
			scrollTo(0, 0, {
				ease: 'inOutQuad',
				duration: 200,
			});

			main_container.style.opacity = 0;

			ev.preventDefault();
			const href_target = ev.target.getAttribute('href');
			if (href_target) {
				setTimeout(() => {
					window.location = href_target;
				}, 1000);
			}
		});
	}

	// info-notices
	let notice_bottom = document.querySelector('.notice-bottom');
	let notice_right = document.querySelector('.notice-right');
	let notice_top = document.querySelector('.notice-top');;

	// current chapter heading
	let current_chapter_heading = document.querySelector('.current-chapter');

	// timing: starter-animation delay 7.5s, no-starter-animation delay 2.5s
	let starter_timeout = 2500;

	// intro-animations – only used on first-chapter & starter-timing
	if (current_chapter_id == 1) {
		let intro_wrapper = document.querySelector('.intro-wrapper');
		setTimeout(() => {
			intro_wrapper.style.opacity = 0;
			intro_wrapper.style.pointerEvents = 'none';
		}, 5000);
		setTimeout(() => {
			intro_wrapper.style.display = 'none';
			intro_wrapper.style.filter = 'none';
			intro_wrapper.style.webkitFilter = 'none';
		}, 8000);


		starter_timeout = 7500;
	}

	setTimeout(() => {
		main_container.style.opacity = 1;
		current_chapter_heading.classList.add('jumplow-animation');
		show(notice_top);
		show(notice_bottom);
		show(notice_right);
	}, starter_timeout);


	let flickity = new Flickity( '.slider-main', {
		// options, defaults listed

		accessibility: true,
		// enable keyboard navigation, pressing left & right keys

		adaptiveHeight: true,
		// set carousel height to the selected slide

		autoPlay: false,
		// advances to the next cell
		// if true, default is 3 seconds
		// or set time between advances in milliseconds
		// i.e. `autoPlay: 1000` will advance every 1 second

		cellAlign: 'center',
		// alignment of cells, 'center', 'left', or 'right'
		// or a decimal 0-1, 0 is beginning (left) of container, 1 is end (right)

		cellSelector: '.slider-cell',
		// specify selector for cell elements

		contain: false,
		// will contain cells to container
		// so no excess scroll at beginning or end
		// has no effect if wrapAround is enabled

		draggable: false,//'>1',
		// enables dragging & flicking
		// if at least 2 cells

		//dragThreshold: 0,
		// number of pixels a user must scroll horizontally to start dragging
		// increase to allow more room for vertical scroll for touch devices

		freeScroll: false,
		// enables content to be freely scrolled and flicked
		// without aligning cellSelector

		selectedAttraction: 1,

		friction: 1,
		// smaller number = easier to flick farther

		groupCells: false,
		// group cells together in slides

		initialIndex: 0,
		// zero-based index of the initial selected cell

		lazyLoad: 3,
		// enable lazy-loading images
		// set img data-flickity-lazyload="src.jpg"
		// set to number to load images adjacent cells

		percentPosition: false,
		// sets positioning in percent values, rather than pixels
		// Enable if items have percent widths
		// Disable if items have pixel widths, like images

		prevNextButtons: true,
		// creates and enables buttons to click to previous & next cells

		pageDots: true,
		// create and enable page dots

		resize: true,
		// listens to window resize events to adjust size & positions

		rightToLeft: false,
		// enables right-to-left layout

		setGallerySize: false,
		// sets the height of gallery
		// disable if gallery already has height set with CSS

		watchCSS: false,
		// watches the content of :after of the element
		// activates if #element:after { content: 'flickity' }

		wrapAround: false
		// at end of cells, wraps-around to first for infinite scrolling

	});


	function resize () {
		vph = window.innerHeight;
		// reset "real" viewport-height for mobile-browsers if we are on landscape-orientation
		vh = vph * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);

		// set sizes and positions
		info_container.el_a_height = document.querySelector('.container.aside.left').getBoundingClientRect().height;
		info_container.el_b_height = document.querySelector('.container.aside.right').getBoundingClientRect().height;
		info_container.el_a_top = document.querySelector('.container.aside.left').getBoundingClientRect().top;

		// set flickity-page-dots' width relative to flickity-vieport's width
		const flickity_vpw = flickity.viewport.getBoundingClientRect().width; // flickity's own width class is updated after resize
		let flickity_dots = flickity.pageDots.dots;
		for (var i = flickity.pageDots.dots.length - 1; i >= 0; i--) {
			flickity.pageDots.dots[i].style.width = Math.floor(1 * flickity_vpw / flickity.pageDots.dots.length) + 'px';
			flickity.pageDots.dots[i].style.height = Math.floor(0.75 * flickity_vpw / flickity.pageDots.dots.length) + 'px';
		}
		// additional marign for info-container on mobile
		if (Modernizr.touchevents) {
			//get address-bar size
			browser_bars_height = window.outerHeight - vph;
			if (window.orientation === 0 || window.orientation === 180) {
				info_container.el_a.style.marginTop = 100*vh + browser_bars_height + 'px';
			} else {
				info_container.el_a.style.marginTop = 0;
			}
		}
	};

	resize();
	let	resize_debounced = debounce(500, resize);
	const resize_ev = (Modernizr.touchevents) ? 'orientationchange' : 'resize';
	window.addEventListener(resize_ev, resize_debounced);

	function scroll () {
		resize();
	};

	let	scroll_debounced = debounce(50, scroll);
	window.addEventListener('scroll', () => {
		if (document.documentElement.scrollTop >= vph) {
			hide(notice_bottom);
		} else {
			let scroll_rel = Math.min(Math.max(parseInt(100*(vph - document.documentElement.scrollTop)/vph), 30), 100);
			main_container.style.filter = 'brightness('+scroll_rel/100+')';
		}
		scroll_debounced();
	});


	function hide (target) {
		if (!target.classList.contains('hidden')) {
			target.classList.add('hidden');
		}
	}
	function show (target) {
		if (target.classList.contains('hidden')) {
			target.classList.remove('hidden');
		}
	}


	let current_chapter_index = document.querySelector('.current-index');

	// similar to 'cellChange'
	flickity.on('settle', (ev) => {
		if (ev >= 1) {
			// hide info-notices after first cell-change
			hide(notice_top);
			hide(notice_bottom);
			hide(notice_right);
			// enable prev-buttons functionality on first slide
			flickity.prevButton.element.style.pointerEvents = 'auto';
		} else {
			// disable prev-buttons functionality on first slide
			flickity.prevButton.element.style.pointerEvents = 'none';
		}
		if (flickity.selectedIndex == flickity.cells.length - 1) {
			// do some thing when end is reached
			if (next_chapter_url) {
				// if next chapter is available insert link to next chapter
				flickity.nextButton.element.innerHTML = '<a class="chapter-transition chapter-button chapter-button--next" href="'+next_chapter_url+'"></a>';
				show(notice_right);
			} else {
				// disable next-buttons functionality
				flickity.nextButton.element.style.pointerEvents = 'none';
				show(notice_bottom);


				/* continue in 2020 notice */
				if (current_chapter_id === 5) {
					setTimeout(() => {
						alert('🔮 Link in Bio takes a break. It will continue in January next year!');
					}, 1500);
				}

			}
		} else if (flickity.selectedIndex < flickity.cells.length - 1) {
			if (next_chapter_url) {
				// if next chapter is available remove link to next chapter
				flickity.nextButton.element.innerHTML = '';
			} else {
				// re-enable next-buttons functionality
				flickity.nextButton.element.style.pointerEvents = 'auto';
			}
		}
		if (info_container.el_a_top < vph) {
			// hide info-containers on load if view
			// use extra space for scroll-top to hide all browser-bars agian
			scrollTo(0, -2*browser_bars_height, {
				ease: 'inOutQuad',
				duration: 200,
			});
		}
		// update progress in menu-list-item's current-index
		const progress = 100 * (flickity.selectedIndex) / (flickity.cells.length-1);
		current_chapter_index.style.backgroundSize = progress+0.5+'% 100%';

	});

	notice_bottom.addEventListener('touchend', () => {
		// hide info-containers
		scrollTo(0, (vph*0.8 + browser_bars_height), {
			ease: 'inOutQuad',
			duration: 400,
		});
	});


	// mobile touch zoom fixes
	document.addEventListener('touchmove', (ev) => {
		if (ev.scale !== 1) {
			ev.preventDefault();
		}
	}, false);
	let lastTouchEnd = 0;
	document.addEventListener('touchend', (ev) => {
		const now = (new Date()).getTime();
		if (now - lastTouchEnd <= 300) {
			ev.preventDefault();
		}
		lastTouchEnd = now;
	}, false);


	// iOS font ("ü") rendering issue
	let iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	let char_change_el = document.querySelector('.char-change');
	if (iOS) { char_change_el.innerHTML = 'Michel Esselbruegge'; }

};
